'use strict';

angular.module('enervexSalesappApp').factory('ApplianceCategory', function ($resource) {
	return $resource('/api/appliance-categories/:id', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		}
	});
});
